import React, { useEffect, useState } from 'react';
import {
  CircleAccordion,
  ContainerAccordion,
  ContentAccordion,
  HeaderAccordion,
  LabelAccordion,
  MethodContainerAccordion,
} from './styles';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import {
  ComponentPaymentProps,
  MethodFaturamento,
  ScreenPaymentsProps,
} from '../../types/context';
import { FormaPagamento } from '../../types/Plan';

interface PaymentMethod {
  id: string;
  label: string;
  Component: React.FC<ComponentPaymentProps>;
}

interface PaymentAccordionProps {
  methods: PaymentMethod[];
  screen: ScreenPaymentsProps;
  lastCreditCard?: boolean;
}

const PaymentAccordion: React.FC<PaymentAccordionProps> = ({
  methods,
  screen,
  lastCreditCard,
}) => {
  const [resetFlag, setResetFlag] = useState(false);

  const {
    handleMethodFaturamento,
    selectedMethod,
    setSelectedMethod,
    showLastCreditCard,
    creditCard,
  } = useMeuPlano();

  const toggleMethod = (id: string) => {
    if (selectedMethod === id) {
      setSelectedMethod(null);
      setResetFlag(true);
      handleMethodFaturamento(null);
      setTimeout(() => setResetFlag(false), 0);
    } else {
      handleMethodFaturamento(id as MethodFaturamento);
      setSelectedMethod(id);
      setResetFlag(false);
    }
  };

  return (
    <ContainerAccordion>
      {showLastCreditCard && lastCreditCard && (
        <MethodContainerAccordion key="last-credit-card">
          <HeaderAccordion onClick={() => toggleMethod('last-credit-card')}>
            <CircleAccordion selected={selectedMethod === 'last-credit-card'} />
            <LabelAccordion>
              Cartão de Crédito com final{' '}
              {creditCard.des_dados_json.creditCard?.lastFourDigits}
            </LabelAccordion>
          </HeaderAccordion>
        </MethodContainerAccordion>
      )}

      {methods.map((method) => (
        <MethodContainerAccordion key={method.id}>
          <HeaderAccordion onClick={() => toggleMethod(method.id)}>
            <CircleAccordion selected={selectedMethod === method.id} />
            <LabelAccordion>
              {showLastCreditCard &&
              lastCreditCard &&
              method.id === 'cartao-credito'
                ? `Novo Cartão de Crédito`
                : method.label}
            </LabelAccordion>
          </HeaderAccordion>
          <ContentAccordion isOpen={selectedMethod === method.id}>
            {selectedMethod === method.id && (
              <method.Component reset={resetFlag} screen={screen} />
            )}
          </ContentAccordion>
        </MethodContainerAccordion>
      ))}
    </ContainerAccordion>
  );
};

export default PaymentAccordion;
